/**
 * 跟单广场
 */
import { create } from 'zustand'
import { createTrackedSelector } from 'react-tracked'
import { ProjectSortRuleType, ProjectTagsEnum, defaultProjectFilters } from '@/constants/copy-trading'
import produce from 'immer'
import { IStoreEnum } from '@/typings/store/common'
import { getCodeDetailListBatch } from '@/apis/common'
import { CopyTradingUserInfoReqResp } from '@/typings/api/copy-trading/square'

type IStore = ReturnType<typeof getStore>

const getStore = (set, get) => ({
  /** 用户当前信息 (跟单人信息、带单人信息、带单申请) */
  copyTradingUserInfo: {} as CopyTradingUserInfoReqResp,
  updateCopyTradingUserInfo: (newCopyTradingUserInfo: CopyTradingUserInfoReqResp) => {
    set((store: IStore) => {
      return produce(store, _store => {
        _store.copyTradingUserInfo = newCopyTradingUserInfo
      })
    })
  },
  /** 当前/历史项目排序规则，默认：综合排序 */
  projectSortRule: ProjectSortRuleType.COMPREHENSIVE,
  updateProjectSortRule: sortRule =>
    set(
      produce((state: IStore) => {
        state.projectSortRule = sortRule
      })
    ),
  /** 当前/历史项目筛选条件 */
  projectFilters: defaultProjectFilters,
  updateProjectFilters: filters =>
    set(
      produce((state: IStore) => {
        state.projectFilters = filters
      })
    ),
  /** 项目标签数据字典 */
  projectTagsEnums: {
    /** 交易周期 */
    tradingCycleEnum: {
      codeKey: ProjectTagsEnum.followCycleType,
      enums: [],
    } as IStoreEnum,
    /** 交易习惯 */
    tradingHabitEnum: {
      codeKey: ProjectTagsEnum.followHabitType,
      enums: [],
    } as IStoreEnum,
    /** 技术手段 */
    tradingToolEnum: {
      codeKey: ProjectTagsEnum.followTechnologyType,
      enums: [],
    } as IStoreEnum,
    /** 用户标签 */
    followUserTagEnum: {
      codeKey: ProjectTagsEnum.followUserTag,
      enums: [],
    } as IStoreEnum,
  },
  async fetchProjectTagsEnums() {
    const state: IStore = get()
    const data = await getCodeDetailListBatch(Object.values(state.projectTagsEnums).map(item => item.codeKey))
    set(
      produce((draft: IStore) => {
        const items = Object.values(draft.projectTagsEnums)
        items.forEach((item, index) => {
          item.enums = data[index].map(enumValue => {
            return {
              label: enumValue.codeKey,
              value: enumValue.codeVal,
            }
          })
        })
      })
    )
  },
  /** 用户 id */
  userId: '',
  updateUserId: uid =>
    set(
      produce((state: IStore) => {
        state.userId = uid
      })
    ),
})

const baseCopyTradingStore = create(getStore)
const useCopyTradingStore = createTrackedSelector(baseCopyTradingStore)
export { useCopyTradingStore, baseCopyTradingStore }
