import request, { MarkcoinRequest } from '@/plugins/request'
import {
  ICopyTradingFollowerAccountListReq,
  ICopyTradingFollowerAccountListResp,
  IFollowBusinessConfigReq,
  IFollowBusinessConfigResp,
  IFollowProjectGetByIdReq,
  IFollowProjectGetByIdResp,
  IFollowSettingCancelReq,
  IFollowSettingCancelResp,
  IFollowSettingInfoReq,
  IFollowSettingSaveReq,
  IFollowSettingSaveResp,
  IFollowStrategyListReq,
  IFollowStrategyListResp,
  IFollowStrategyRemoveReq,
  IFollowStrategyRemoveResp,
  IFollowStrategySaveReq,
  IFollowStrategySaveResp,
} from '@/typings/api/copy-trading/settings'

/**
 * 查询当前项目详情
 * https://yapi.nbttfc365.com/project/100/interface/api/20197
 * */
export const getFollowProjectGetById: MarkcoinRequest<IFollowProjectGetByIdReq, IFollowProjectGetByIdResp> = params => {
  return request({
    path: '/v1/follow/project/getById',
    method: 'GET',
    params,
  })
}

/**
 * 商户的跟单配置
 * https://yapi.nbttfc365.com/project/100/interface/api/19537
 * */
export const getFollowBusinessConfig: MarkcoinRequest<IFollowBusinessConfigReq, IFollowBusinessConfigResp> = params => {
  return request({
    path: '/v1/follow/businessConfig',
    method: 'GET',
    params,
  })
}

/**
 * 跟单设置 - 选择账户列表
 * https://yapi.nbttfc365.com/project/100/interface/api/19567
 * */
export const getCopyTradingMainAccountList: MarkcoinRequest<
  ICopyTradingFollowerAccountListReq,
  ICopyTradingFollowerAccountListResp
> = params => {
  return request({
    path: '/v1/follow/person/account/list',
    method: 'GET',
    params,
  })
}

/**
 * 跟单账户列表
 * https://yapi.nbttfc365.com/project/100/interface/api/19567
 * */
export const getCopyTradingFollowerAccountList: MarkcoinRequest<
  ICopyTradingFollowerAccountListReq,
  ICopyTradingFollowerAccountListResp
> = params => {
  return request({
    path: '/v1/perpetual/follow/account/list',
    method: 'GET',
    params,
  })
}

/**
 * [根据项目 ID 查询跟单设置详情↗](https://yapi.nbttfc365.com/project/100/interface/api/19807)
 * */
export const getFollowSettingInfo: MarkcoinRequest<IFollowSettingInfoReq, IFollowSettingSaveReq> = params => {
  return request({
    path: '/v1/follow/setting/info',
    method: 'GET',
    params,
  })
}

/**
 * 跟单/修改设置
 * https://yapi.nbttfc365.com/project/100/interface/api/19621
 * */
export const postFollowSettingSave: MarkcoinRequest<IFollowSettingSaveReq, IFollowSettingSaveResp> = data => {
  return request({
    path: '/v1/follow/setting/save',
    method: 'POST',
    data,
  })
}

/**
 * 取消跟单
 * https://yapi.nbttfc365.com/project/100/interface/api/19735
 * */
export const postFollowSettingCancel: MarkcoinRequest<IFollowSettingCancelReq, IFollowSettingCancelResp> = data => {
  return request({
    path: '/v1/follow/setting/cancel',
    method: 'POST',
    data,
  })
}

/**
 * 查询策略列表
 * https://yapi.nbttfc365.com/project/100/interface/api/19729
 * */
export const getFollowStrategyList: MarkcoinRequest<IFollowStrategyListReq, IFollowStrategyListResp[]> = params => {
  return request({
    path: '/v1/follow/strategy/list',
    method: 'GET',
    params,
  })
}

/**
 * 新建/修改策略
 * https://yapi.nbttfc365.com/project/100/interface/api/19717
 * */
export const postFollowStrategySave: MarkcoinRequest<IFollowStrategySaveReq, IFollowStrategySaveResp> = data => {
  return request({
    path: '/v1/follow/strategy/save',
    method: 'POST',
    data,
  })
}

/**
 * 删除策略
 * https://yapi.nbttfc365.com/project/100/interface/api/19741
 * */
export const postFollowStrategyRemove: MarkcoinRequest<IFollowStrategyRemoveReq, IFollowStrategyRemoveResp> = data => {
  return request({
    path: '/v1/follow/strategy/remove',
    method: 'POST',
    data,
  })
}
