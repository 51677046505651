import { getFollowBusinessConfig } from '@/apis/copy-trading/common'
import { CopyTradingUserTakerStatusEnum } from '@/constants/copy-trading/common'
import { baseCopyTradingStore } from '@/store/copy-trading'
import { baseCopyTradingSettingsStore } from '@/store/copy-trading/settings'
import { baseUserStore } from '@/store/user'
import { getFollowUserStatus } from '@/apis/copy-trading'
import { getCopyTradingMainAccountList } from '@/apis/copy-trading/settings'
import { baseFuturesStore } from '@/store/futures'
import { t } from '@lingui/macro'
import { baseCommonStore } from '@/store/common'
import { ThemeEnum } from '@nbit/chart-utils'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { Type } from '@/components/lazy-image'
import { FuturesTradeAccountTypeEnum, FuturesTradeModeEnum } from '@/constants/future/common'
import { link } from '../link'
import { getCopyTradingRoutePath } from '../route/copy-trading'
import { getTextFromStoreEnums } from '../store'

/** 获取合约跟单图片地址 */
export const getCopyTradingOssImageUrl = (name: string, hasTheme = false) => {
  const commonState = baseCommonStore.getState()
  let themeName = ''
  if (hasTheme) themeName = commonState.theme === ThemeEnum.dark ? '_black' : '_white'

  const bannerBgImage = `${oss_svg_image_domain_address}${name}${themeName}${Type.png}`
  return bannerBgImage
}

/**
 * 获取商户的跟单配置
 */
export const getCopyTradingBusinessSettings = async () => {
  const { updateCopyTradingBusinessConfig } = baseCopyTradingSettingsStore.getState()
  const res = await getFollowBusinessConfig({})
  const { isOk, data } = res || {}
  if (!isOk || !data) {
    return
  }
  updateCopyTradingBusinessConfig(data)
  return data
}

/**
 * 获取当前用户跟单/带单开通状态
 * @returns
 */
export const getCopyTradingUserStatus = () => {
  const {
    copyTradingUserInfo: { takerUser, followUser, takerApply },
  } = baseCopyTradingStore.getState()

  return {
    takerStatus: !takerUser
      ? !takerApply
        ? CopyTradingUserTakerStatusEnum.auditing
        : CopyTradingUserTakerStatusEnum.notOpen
      : CopyTradingUserTakerStatusEnum.open,
    followerStatus: !followUser ? CopyTradingUserTakerStatusEnum.notOpen : CopyTradingUserTakerStatusEnum.open,
  }
}

/**
 * 获取跟单账户名字
 */
export const getFollowAccountName = (accountName: string | undefined) => {
  const newAccountName = accountName
    ? t({
        id: 'helper_copy_trading_common_kidyok2tah',
        values: { 0: accountName },
      })
    : undefined
  return newAccountName
}

/**
 * 跳转登录页面
 * @param redirect 重定向路径
 */
export const linkLogin = (redirect?: string) => {
  const { isLogin } = baseUserStore.getState()
  if (!isLogin) {
    link(`/login?redirect=${redirect || getCopyTradingRoutePath()}`)
    return false
  }

  return true
}
/**
 * 格式化比例
 * @param val 数值
 */
export const getscale = val => {
  if (!val) return 0
  let percentage = val * 100
  return percentage.toFixed(2)
}
/**
 * 获取用户跟单/带单状态信息/带单主账户信息
 */
export const getUserCopyTradingStatus = async () => {
  const { isLogin, userInfo, setUserInfo } = baseUserStore.getState()
  const { updateCopyTradingUserInfo } = baseCopyTradingStore.getState()

  if (!isLogin) return

  const res = await getFollowUserStatus({})

  if (!res?.isOk || !res?.data) return

  // 已开通带单交易员时查询带单主账户信息
  const isCopyTradingLeader = !!res?.data?.takerUser
  let takerAccount = {}
  if (isCopyTradingLeader) {
    const takerRes = await getCopyTradingMainAccountList({
      typeInd: FuturesTradeAccountTypeEnum.takerAccount,
    })
    const { data } = takerRes

    if (data?.list && data?.list?.length > 0) {
      takerAccount = data?.list[0]
    }
  }

  const takerUser = { ...takerAccount, ...res?.data?.takerUser }

  updateCopyTradingUserInfo({ ...res?.data, takerUser })
  setUserInfo({
    isOpenCopyTrading: !!res?.data?.followUser,
    isCopyTradingLeader,
    isCopyTradingLeaderApplying: !!res?.data?.takerApply,
  })

  return res?.data
}

/**
 * 获取用户是否有跟单/带单状态信息
 */
export const getUserTradingStatus = async () => {
  const { isLogin, userInfo, setUserInfo } = baseUserStore.getState()

  if (!isLogin) return

  const res = await getFollowUserStatus({})
  if (!res?.isOk || !res?.data) return

  setUserInfo({
    ...userInfo,
    isOpenCopyTrading: !!res?.data?.followUser,
    isCopyTradingLeader: !!res?.data?.takerUser,
  })

  return res?.data
}

/**
 * 获取项目标签展示
 */
export const getProjectTagName = (tag: string) => {
  const { projectTagsEnums } = baseCopyTradingStore.getState()
  return getTextFromStoreEnums(tag, [
    ...projectTagsEnums.tradingCycleEnum.enums,
    ...projectTagsEnums.tradingHabitEnum.enums,
    ...projectTagsEnums.tradingToolEnum.enums,
  ])
}

export const getPositionAccountType = (accountType?: FuturesTradeAccountTypeEnum) => {
  if (accountType) return accountType

  const { tradeModeType } = baseFuturesStore.getState()
  switch (tradeModeType) {
    case FuturesTradeModeEnum.copyTrading:
      return FuturesTradeAccountTypeEnum.takerAccount
    case FuturesTradeModeEnum.common:
    default:
      return FuturesTradeAccountTypeEnum.futuresAccount
  }
}
