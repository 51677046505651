/**
 * 合约跟单 - 公共
 */

import request, { MarkcoinRequest } from '@/plugins/request'
import { CopyTradingBusinessConfigReq, CopyTradingBusinessConfigResp } from '@/typings/api/copy-trading/common'

/**
 * [商户的跟单配置↗](https://yapi.nbttfc365.com/project/100/interface/api/19537)
 * */
export const getFollowBusinessConfig: MarkcoinRequest<
  CopyTradingBusinessConfigReq,
  CopyTradingBusinessConfigResp
> = params => {
  return request({
    path: '/v1/follow/businessConfig',
    method: 'GET',
    params,
  })
}
